/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-11",
    versionChannel: "nightly",
    buildDate: "2024-07-11T00:05:52.246Z",
    commitHash: "ac9b39a652dad703638a8e55507b6db9b2b7136f",
};
